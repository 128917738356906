exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-hcp-tsx": () => import("./../../../src/pages/hcp.tsx" /* webpackChunkName: "component---src-pages-hcp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-hcp-page-tsx": () => import("./../../../src/templates/HcpPage.tsx" /* webpackChunkName: "component---src-templates-hcp-page-tsx" */),
  "component---src-templates-languages-page-tsx": () => import("./../../../src/templates/LanguagesPage.tsx" /* webpackChunkName: "component---src-templates-languages-page-tsx" */),
  "component---src-templates-multi-languages-page-tsx": () => import("./../../../src/templates/MultiLanguagesPage.tsx" /* webpackChunkName: "component---src-templates-multi-languages-page-tsx" */),
  "component---src-templates-patient-page-tsx": () => import("./../../../src/templates/PatientPage.tsx" /* webpackChunkName: "component---src-templates-patient-page-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/SimplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */)
}

